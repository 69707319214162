const BasicInfo = ({ matchedValue, currentOrderToDisplay, isAllocated }) => {
  return (
    <>
      {isAllocated && (
        <div className="font-bold text-7xl p-3 bg-stone-300 rounded-lg">
          {currentOrderToDisplay?.stockLocationName || currentOrderToDisplay?.stockLocationName}
          {currentOrderToDisplay?.stockLocationPosition && `-${currentOrderToDisplay?.stockLocationPosition}`}
        </div>
      )}
      <div className="flex flex-row w-full justify-evenly items-center">
        <span>Matched result:</span>
        <span className="border-1 bg-stone-300 p-2 font-bold rounded-md">{matchedValue}</span>
      </div>
      <div className="w-full grid grid-cols-3">
        <div className="col-span-1">Customer</div>
        <div className="col-span-2 font-bold">
          {currentOrderToDisplay?.customerFirstName} {currentOrderToDisplay?.customerLastName}
        </div>
        <div>Order #</div>
        <div className="col-span-2 font-bold">{currentOrderToDisplay?.orderNo || 'N/A'}</div>
        <div>State</div>
        <div className="col-span-2 font-bold">{currentOrderToDisplay?.orderState || 'N/A'}</div>
        <div>Origin</div>
        <div className="col-span-2 font-bold">{currentOrderToDisplay?.origin || 'N/A'}</div>
        <div>Manifest</div>
        <div className="col-span-2 font-bold">{currentOrderToDisplay?.manifest?.name || 'N/A'}</div>
      </div>
    </>
  )
}

export default BasicInfo
