import { Fragment, useEffect, useRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const MenuItem = styled.div`
  margin: 0 0.5rem;
  border-left: 3px solid transparent;
  border-radius: 0.5rem;
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #fdf7ed;
    border-color: #ff6700;
    `};
`

export default function Dropdown({ theValue, onSelect, options, isSearchable, disabled = false }) {
  const [searchValue, setSearchValue] = useState(theValue?.label || '')
  const [viewableList, setViewableList] = useState(options)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    if (isSearchable && searchValue)
      setViewableList(options.filter(({ label }) => label.toLowerCase().includes(searchValue.toLowerCase())))
  }, [isSearchable, options, searchValue])

  useEffect(() => {
    setViewableList(options)
    setSearchValue(theValue?.label || '')
  }, [isDropdownOpen, options, theValue])

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ close, open }) => {
        setIsDropdownOpen(open)
        return (
          <>
            <div>
              <Menu.Button
                disabled={disabled}
                className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <MenuButton
                  value={theValue?.label}
                  isOpen={open}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  isSearchable={isSearchable}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 min-w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-900">
                <div className="py-1">
                  {viewableList.map(({ value, label }) => (
                    <Menu.Item
                      key={value}
                      onClick={() => {
                        onSelect(value, label)
                        close()
                      }}
                    >
                      <MenuItem className="relative p-2" isSelected={value === theValue?.value}>
                        {label}
                      </MenuItem>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )
      }}
    </Menu>
  )
}

const MenuButton = ({ value, isOpen, searchValue, setSearchValue, isSearchable }) => {
  const inputRef = useRef()

  useEffect(() => {
    let timeOut
    if (isSearchable && isOpen) {
      timeOut = setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 0)
    }
    return () => clearTimeout(timeOut)
  }, [isSearchable, isOpen])

  return (
    <>
      {isOpen && isSearchable ? (
        <input
          className="w-full"
          ref={inputRef}
          onClick={(e) => e.stopPropagation()}
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      ) : (
        <>
          {value || 'Select'}
          <FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </>
      )}
    </>
  )
}
