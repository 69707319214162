import React, { useContext } from 'react'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import { AVAILABLE_ROUTES } from '../fixtures/routerConfig'
import { LOCAL_STORAGE_TOKEN_KEY } from '../fixtures/localStorageConstants'
import useAuth from '../customHooks/useAuth'
import { ToastContext } from '../contexts/toastContext'

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate()

  const { errorToast } = useContext(ToastContext)

  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || ''
  axios.defaults.headers.common.token = token

  axios.interceptors.response.use(
    (res) => {
      return res
    },
    (error) => {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        switch (error?.response?.data?.code) {
          case 0:
            signOutUser('Could not query auth server')
            break
          case 10:
            signOutUser('You are not an admin')
            break
          case 100:
            signOutUser('Token is not provided')
            break
          case 101:
            signOutUser('Token is not valid for decoding')
            break
          case 200:
            signOutUser('Token is not having valid auth data')
            break
          case 201:
            signOutUser('Token is not active for the user account')
            break
          case 202:
            signOutUser('Token has expired')
            break
          case 203:
            signOutUser('This account is suspended')
            break
          default:
            if (error?.response?.data?.code === 900) errorToast('Your subscription plan does not have this feature')
            else errorToast('Token is not valid')
            break
        }
      } else if (error?.response?.data?.code === 900) errorToast('Your subscription plan does not have this feature')
      else return Promise.reject(error)
    }
  )

  const isAuthenticated = useAuth(token)

  const signOutUser = (message) => {
    errorToast(message)
    navigate(AVAILABLE_ROUTES.LOGIN)
  }

  axios.interceptors.response.use(
    (res) => {
      return res
    },
    // eslint-disable-next-line consistent-return
    (error) => {
      if (error?.response?.status === 403) {
        switch (error?.response?.data?.code) {
          case 0:
            signOutUser('Could not query auth server')
            break
          case 10:
            signOutUser('You are not an admin')
            break
          case 100:
            signOutUser('Token is not provided')
            break
          case 101:
            signOutUser('Token is not valid for decoding')
            break
          case 200:
            signOutUser('Token is not having valid auth data')
            break
          case 201:
            signOutUser('Token is not active for the user account')
            break
          case 202:
            signOutUser('Token has expired')
            break
          case 203:
            signOutUser('This account is suspended')
            break
          case 300:
            // INFO: not showing error pop ups, otherwise might overwhelm user
            // in cases, when we are running APIs on the background
            // errorToast('API blocked. Access Denied')
            break
          default:
            signOutUser('Token is not valid')
            break
        }
      } else return Promise.reject(error)
    }
  )

  return isAuthenticated ? <>{children}</> : <Navigate to={AVAILABLE_ROUTES.LOGIN} />
}

export default PrivateRoute
