import { Html5Qrcode } from 'html5-qrcode'
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import { manualScan } from '../../apis/scanner'
import { ToastContext } from '../../contexts/toastContext'
import NotFoundController from '../NotFoundController/NotFoundController'

const Border = styled.div`
  border-color: ${({ barcodeFound }) => (barcodeFound ? 'red' : 'grey')};
`

const qrcodeRegionId = 'html5qr-code-full-region'

const BarcodeScanner = forwardRef(
  (
    { field, target, setScannedInfo, setErrorMessage, refreshState, handleManualScan, SLs, handle417ErrorOnScan },
    ref
  ) => {
    const [scannedValue, setScannedValue] = useState('')
    const [checkedValues, setCheckedValues] = useState([])
    const [previouslyReceivedValues, setPreviouslyReceivedValues] = useState([])
    const [showNotScannableHandler, setShowNotScannableHandler] = useState(false)

    const { errorToast } = useContext(ToastContext)

    useImperativeHandle(ref, () => ({
      clearState() {
        setScannedValue('')
        setCheckedValues([])
      },
    }))

    const handleBarcodeScan = useCallback(
      async (value) => {
        const body = {
          value,
          field,
          target,
          isManual: false,
        }
        try {
          const res = await manualScan(body)
          setScannedInfo(res)
        } catch (err) {
          console.error('Error ocurred during manual scan: ', err)
          if (err.response?.status === 400) {
            errorToast(err.response?.data.errMsg)
          } else if (err.response?.status === 417) {
            handle417ErrorOnScan({
              value,
              errorType: err.response?.data?.errorType,
              errInfo: err.response?.data?.errInfo,
              isManualScan: true,
            })
            if (err.response?.data?.errorType === 'ScanTargetBoxStateMismatch') {
              if (!previouslyReceivedValues.includes(value)) setPreviouslyReceivedValues((list) => [...list, value])
            }
          }
        }
      },
      [field, target, setScannedInfo, errorToast, previouslyReceivedValues, handle417ErrorOnScan]
    )

    useEffect(() => {
      const html5QrCode = new Html5Qrcode(
        qrcodeRegionId
        // { formatsToSupport: [Html5QrcodeSupportedFormats.CODE_128] }
        // INFO: currently put formatsToSupport limitation to improve performance and decrease errors
      )
      try {
        html5QrCode
          .start({ facingMode: 'environment' }, { fps: 10 }, setScannedValue)
          .catch((err) => console.log('Error when starting scanner: ', err))
      } catch (err) {
        console.log('Error ocurred trying to start scanner')
      }
      return () => {
        try {
          html5QrCode
            .stop()
            .then((ignore) => {
              console.log('stopped scanning barcode')
            })
            .catch((err) => {
              console.log('Error when stopping')
            })
        } catch (err) {
          console.log('error ocurred trying to stop scanner')
        }
      }
    }, [])

    useEffect(() => {
      if (!checkedValues.includes(scannedValue) && scannedValue) {
        handleBarcodeScan(scannedValue)
        setCheckedValues((list) => [...list, scannedValue])
      }
    }, [scannedValue, checkedValues, handleBarcodeScan])

    useEffect(() => {
      let timeout
      if (scannedValue) {
        setErrorMessage('')
        timeout = setTimeout(() => {
          setScannedValue('')
        }, 4000)
      }
      return () => clearTimeout(timeout)
    }, [scannedValue, setErrorMessage])

    return (
      <div className="flex flex-col gap-3">
        <Border barcodeFound={Boolean(scannedValue)} className="border-4">
          <div id={qrcodeRegionId} className="w-[calc(100vw-5rem)] max-w-screen-sm" />
        </Border>
        {scannedValue && (
          <div className="flex flex-col items-center gap-1 text-3xl font-bold">
            <div>{scannedValue}</div>
          </div>
        )}
        {showNotScannableHandler ? (
          <div className="flex flex-col gap-2 p-2 border">
            <button
              onClick={() => setShowNotScannableHandler(false)}
              className="w-fit self-end font-bold text-gray-500"
            >
              Hide
            </button>
            <NotFoundController
              recognizedList={checkedValues.filter((v) => !previouslyReceivedValues.includes(v))}
              field={field}
              handleManualScan={handleManualScan}
              refreshState={refreshState}
              SLs={SLs}
            />
          </div>
        ) : (
          <button
            onClick={() => setShowNotScannableHandler(true)}
            className="w-full rounded-3xl bg-marker text-white text-md py-2 px-5 text-center flex flex-row items-center justify-center"
          >
            Cannot Scan?
          </button>
        )}
      </div>
    )
  }
)

export default BarcodeScanner
