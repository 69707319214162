import { useNavigate } from 'react-router-dom'
import { AVAILABLE_ROUTES } from '../../fixtures/routerConfig'
import { LOCAL_STORAGE_TOKEN_KEY } from '../../fixtures/localStorageConstants'
import { getStores, logout } from '../../apis/auth'
import { SCAN_TYPES, SCAN_TYPE_LABELS } from '../../fixtures/scanTypesConfig'
import Dropdown from '../Common/Dropdown'
import { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContext } from '../../contexts/toastContext'

const Dashboard = () => {
  const [stores, setStores] = useState([])
  const [currentStore, setCurrentStore] = useState(JSON.parse(sessionStorage.getItem('STORE_ID')))

  const { errorToast } = useContext(ToastContext)

  const handleSelectStore = (storeId, storeName) => {
    setCurrentStore({ name: storeName, id: storeId })
    sessionStorage.setItem('STORE_ID', JSON.stringify({ name: storeName, id: storeId }))
    axios.defaults.headers.common['store-id'] = storeId
  }

  const getAvailableStores = useCallback(async () => {
    try {
      const res = await getStores()
      setStores(res.data.stores)
    } catch (err) {
      console.error('Error ocurred when trying to retrieve stores: ', err)
    }
  }, [])

  useEffect(() => {
    getAvailableStores()
  }, [getAvailableStores])

  const navigate = useNavigate()

  const handleSignOut = async () => {
    try {
      await logout()
    } catch (err) {
      console.error('Error ocurred when trying to logout')
    } finally {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
      navigate(AVAILABLE_ROUTES.LOGIN)
    }
  }

  const handleNavigate = (path) => {
    if (!currentStore) {
      errorToast('Please select store first')
      return
    }
    navigate(path)
  }

  return (
    <div className="flex flex-col gap-6 w-full h-screen p-10 justify-center items-center">
      <div className="flex flex-row gap-2 items-center">
        <div>Store:</div>
        <Dropdown
          theValue={currentStore ? { value: currentStore?.id, label: currentStore?.name || 'No Name' } : null}
          onSelect={(s, l) => handleSelectStore(s, l)}
          options={stores.map(({ name, id }) => {
            return { value: id, label: name || 'No Name' }
          })}
          isSearchable
        />
      </div>
      <button
        className="py-2 px-6 bg-primary rounded-3xl font-bold text-white"
        onClick={() => handleNavigate(AVAILABLE_ROUTES.RECEIVING)}
      >
        {SCAN_TYPE_LABELS[SCAN_TYPES.RECEIVING]}
      </button>
      <button
        className="py-2 px-6 bg-marker rounded-3xl font-bold text-white"
        onClick={() => handleNavigate(AVAILABLE_ROUTES.PLACING)}
      >
        {SCAN_TYPE_LABELS[SCAN_TYPES.PLACING]}
      </button>
      <button
        className="py-2 px-6 bg-green-500 rounded-3xl font-bold text-white"
        onClick={() => handleNavigate(AVAILABLE_ROUTES.SHIPPING_OUT)}
      >
        {SCAN_TYPE_LABELS[SCAN_TYPES.SHIPPING_OUT]}
      </button>
      <button
        className="absolute py-2 px-6 bg-red-500 rounded-3xl font-bold text-white bottom-10"
        onClick={handleSignOut}
      >
        Logout
      </button>
    </div>
  )
}

export default Dashboard
