import axios from 'axios'

export const login = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_DRIVER_API_BACKEND_URL_BASE}/api/users/login`, body)

  return res.data
}

export const logout = async () => {
  const res = await axios.post(`${process.env.REACT_APP_DRIVER_API_BACKEND_URL_BASE}/api/users/logout`)

  return res.data
}

export const getStores = async (term = '') => {
  const res = await axios.get(`${process.env.REACT_APP_DRIVER_API_BACKEND_URL_BASE}/api/stores?term=${term}`)

  return res.data
}
