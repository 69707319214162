import { useEffect, useState } from 'react'
import CreateBoxModal from './CreateBoxModal'
import Dropdown from '../Common/Dropdown'
import ManualScanModal from './ManualScanModal'

const NotFoundController = ({ recognizedList, field, handleManualScan, refreshState, SLs, isCreateOrderVisible }) => {
  const [isBoxModalOpen, setIsBoxModalOpen] = useState(false)
  const [isManualScanModalOpen, setIsManualScanModalOpen] = useState(false)
  const [scannedSelection, setScannedSelection] = useState(null)

  useEffect(() => {
    setScannedSelection(recognizedList[0])
  }, [recognizedList])

  return (
    <div className="flex flex-col items-center gap-2 w-full py-3">
      <div className="flex flex-row w-full justify-between items-center">
        <span>Scanned results:</span>
        <Dropdown
          options={recognizedList.map((name) => {
            return { label: name, value: name }
          })}
          theValue={{ value: scannedSelection, label: scannedSelection }}
          onSelect={setScannedSelection}
        />
      </div>
      {isCreateOrderVisible && (
        <button
          onClick={() => {
            setIsBoxModalOpen(true)
          }}
          className="w-full rounded-3xl bg-primary text-white py-2 px-5"
        >
          Create Order
        </button>
      )}
      <button
        onClick={() => {
          setIsManualScanModalOpen(true)
        }}
        className="w-full rounded-3xl bg-marker text-white text-md py-2 px-5 text-center flex flex-row items-center justify-center"
      >
        Manual Scan
      </button>
      <CreateBoxModal
        placeholderValue={scannedSelection}
        field={field}
        isBoxModalOpen={isBoxModalOpen}
        setIsBoxModalOpen={setIsBoxModalOpen}
        refreshState={refreshState}
        SLs={SLs}
      />
      <ManualScanModal
        placeholderValue={scannedSelection}
        isManualScanModalOpen={isManualScanModalOpen}
        setIsManualScanModalOpen={setIsManualScanModalOpen}
        field={field}
        handleManualScan={handleManualScan}
      />
    </div>
  )
}

export default NotFoundController
