const GroupOrders = ({ groupOrders, id }) => {
  if (!groupOrders || !groupOrders.length) return null
  return (
    <div className="w-full">
      <div>Related Orders</div>
      <div className="flex flex-col gap-3">
        {groupOrders
          .filter((o) => o.id !== id)
          .map((o) => (
            <div key={o.id} className="border border-gray-700 p-2 rounded-lg">
              <div className="font-bold">{o.orderNo}</div>
              <div className="flex flex-row justify-between">
                {o.orderState && <div>State: {o.orderState}</div>}
                {o.origin && <div>Origin: {o.origin}</div>}
              </div>
              <div className="flex flex-row justify-between">
                <div>Qty: {o.quantity}</div>
                <div>Wgt: {o.weight} lbs.</div>
                <div>
                  Vol: {o.volume} ft.<sup>3</sup>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default GroupOrders
