import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { AVAILABLE_ROUTES } from '../fixtures/routerConfig'
import { faArrowLeft, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { getListOfScannableFields, getSLs, manualScan, scanLabelFile } from '../apis/scanner'
import Dropdown from '../components/Common/Dropdown'
import ErrorMessage from '../components/Common/ErrorMessage'
import Webcam from 'react-webcam'
import { FACING_MODE_ENVIRONMENT } from '../fixtures/otherConstants'
import { dataURLtoFile } from '../utils/dataURLttoFile'
import { ToastContext } from '../contexts/toastContext'
import BarcodeScanner from '../components/BarcodeScanner/BarcodeScanner'
import { SCAN_FIELD_TYPES, SCAN_TYPES, SCAN_TYPE_LABELS } from '../fixtures/scanTypesConfig'

const withScanner = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate()
    const target = useLocation().pathname.replace('/', '')

    const { errorToast } = useContext(ToastContext)

    const webCamRef = useRef()
    const barcodeRef = useRef()

    const [field, setField] = useState(null)
    const [scannableFields, setScannableFields] = useState([])
    const [SLs, setSLs] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    const [scannedInfo, setScannedInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [currentSLToPlace, setCurrentSLToPlace] = useState(null)
    const [store, setStore] = useState(null)

    const handleGetScannableFields = useCallback(async () => {
      try {
        const res = await getListOfScannableFields()
        setScannableFields(res.fields)
      } catch (err) {
        console.error('Error ocurred when trying to retrieve scannable fields: ', err)
      }
    }, [])

    const handleGetStockLocations = useCallback(async () => {
      try {
        const res = await getSLs()
        setSLs(res.items)
      } catch (err) {
        console.error('Error ocurred when trying to retrieve Stock Locations list: ', err)
      }
    }, [])

    const refreshState = () => {
      setScannedInfo(null)
      setErrorMessage('')
      barcodeRef?.current?.clearState()
    }

    const handle417ErrorOnScan = useCallback(
      ({ value = undefined, errorType, errInfo, isManualScan = false }) => {
        if (errorType === 'ScanNoResultFound' && isManualScan) {
          setErrorMessage(`Could not find order with ${field} '${value}'`)
        } else if (errorType === 'ScanNoResultFound' && !isManualScan) {
          const listOfOptions = errInfo.recognized
          if (listOfOptions.length === 1 && !listOfOptions[0]) {
            setErrorMessage('No Result found, and none of the fields could be recognized. Please try again')
          } else if (listOfOptions.length) {
            let errorMessage =
              'No Result found, please view scanned results below. Either edit one of them and try a `Manual scan`'
            if (target === SCAN_TYPES.RECEIVING) errorMessage = `${errorMessage} or \`Create Item\``
            setErrorMessage(errorMessage)
            setScannedInfo(errInfo)
          }
        }
        if (errorType === 'ScanTargetBoxStateMismatch') {
          if (target === SCAN_TYPES.RECEIVING && field === SCAN_FIELD_TYPES.BAR_CODE)
            setErrorMessage('This item has been received. (Might have scanned this box twice?)')
          if (target === SCAN_TYPES.RECEIVING && field !== SCAN_FIELD_TYPES.BAR_CODE)
            setErrorMessage('All items for this order have been received. (Might have scanned a box twice?)')
          if (target === SCAN_TYPES.PLACING && errInfo?.boxState === 'BoxStateExpected')
            setErrorMessage('This box has not been `Received` yet')
          if (
            target === SCAN_TYPES.PLACING &&
            errInfo?.boxState === 'BoxStatePlaced' &&
            field === SCAN_FIELD_TYPES.BAR_CODE
          )
            setErrorMessage('This item has been placed. (Might have scanned this box twice?)')
          if (
            target === SCAN_TYPES.PLACING &&
            errInfo?.boxState === 'BoxStatePlaced' &&
            field !== SCAN_FIELD_TYPES.BAR_CODE
          )
            setErrorMessage('All items for this order have been placed. (Might have scanned a box twice?)')
          // TODO: what state is shipped box, figure out and uncomment (also write error messages for different cases)
          // if (target === SCAN_TYPES.PLACING && err.response?.data?.errInfo?.boxState === 'BoxStateShipped')
          // setErrorMessage('This box has been `Shipped Out` already')
        }
      },
      [field, target]
    )

    // explicit functions

    const handleManualScan = useCallback(
      async ({ value, close }) => {
        const body = {
          value,
          field,
          target,
          isManual: true,
        }
        try {
          const res = await manualScan(body)
          setScannedInfo(res)
          setErrorMessage('')
          close && close()
        } catch (err) {
          console.error('Error ocurred during manual scan: ', err)
          if (err.response?.status === 400) {
            errorToast(err.response?.data.errMsg)
          } else if (err.response?.status === 417) {
            handle417ErrorOnScan({
              value,
              errorType: err.response?.data?.errorType,
              errInfo: err.response?.data?.errInfo,
              isManualScan: true,
            })
            close && close()
          }
        }
      },
      [field, setErrorMessage, target, errorToast, handle417ErrorOnScan]
    )

    const handleScanLabel = useCallback(
      async (img, isFile = false) => {
        setIsLoading(true)
        const file = isFile ? img : dataURLtoFile(img)
        const body = new FormData()
        body.append('field', field)
        body.append('target', target)
        body.append('file', file, file.name)
        try {
          const res = await scanLabelFile(body)
          setScannedInfo(res)
          setErrorMessage('')
        } catch (err) {
          console.error('Error ocurred when trying to scan: ', err)
          if (err.response?.status === 500) {
            errorToast(err.response?.data?.errorMsg || 'Error ocurred when trying to scan')
            refreshState()
          } else if (err.response?.status === 400) {
            errorToast(err.response?.data.errMsg)
          } else if (err.response?.status === 417) {
            handle417ErrorOnScan({
              errorType: err.response?.data?.errorType,
              errInfo: err.response?.data?.errInfo,
            })
          }
        } finally {
          setIsLoading(false)
        }
      },
      [field, target, errorToast, handle417ErrorOnScan]
    )

    const handleCaptureImg = useCallback(() => {
      if (!field) {
        errorToast('Select field type from dropdown above before scanning')
        return
      }
      const capturedImg = webCamRef.current.getScreenshot()
      handleScanLabel(capturedImg)
    }, [field, handleScanLabel, errorToast])

    useEffect(() => {
      handleGetScannableFields()
      handleGetStockLocations()
    }, [handleGetScannableFields, handleGetStockLocations])

    useEffect(() => {
      refreshState()
    }, [field, currentSLToPlace])

    useEffect(() => {
      if (scannedInfo && !scannedInfo.scanResult?.scannedValues?.some((v) => Boolean(v))) {
        errorToast('Nothing matched, try again')
        setScannedInfo(null)
      }
    }, [scannedInfo, setScannedInfo, errorToast])

    useEffect(() => {
      try {
        const theStore = JSON.parse(sessionStorage.getItem('STORE_ID')).name
        setStore(theStore)
      } catch (err) {
        console.error('Error ocurred when trying to parse store id, please select store before continuing')
        navigate(AVAILABLE_ROUTES.DASHBOARD)
      }
    }, [])

    const renderScanner = () => {
      if (target === SCAN_TYPES.PLACING && !currentSLToPlace)
        return (
          <div className="text-center font-bold text-stone-500 text-xl">
            Please select SL for placing to start scanning
          </div>
        )
      if (scannedInfo) return null
      if (field === SCAN_FIELD_TYPES.BAR_CODE)
        return (
          <BarcodeScanner
            ref={barcodeRef}
            field={field}
            target={target}
            setScannedInfo={setScannedInfo}
            setErrorMessage={setErrorMessage}
            refreshState={refreshState}
            handleManualScan={handleManualScan}
            handle417ErrorOnScan={handle417ErrorOnScan}
            SLs={SLs}
          />
        )
      else
        return (
          <Webcam
            screenshotQuality={1}
            ref={webCamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: FACING_MODE_ENVIRONMENT,
            }}
            className="z-10"
          />
        )
    }

    const renderCapture = () => {
      if (target === SCAN_TYPES.PLACING && !currentSLToPlace) return null
      if (field !== SCAN_FIELD_TYPES.BAR_CODE)
        return (
          <button
            className="w-full rounded-3xl bg-primary text-white text-3xl py-10 px-5 text-center flex flex-row items-center justify-center"
            onClick={handleCaptureImg}
          >
            {isLoading ? (
              <div className="loader">
                <div className="loader-cover" />
                <div className="loader-inner" />
              </div>
            ) : (
              'Capture'
            )}
          </button>
        )
    }

    return (
      <div className="flex flex-col items-center gap-4 px-10 pb-10 pt-2">
        <div className="flex flex-col w-full items-center gap-2">
          <div className="font-bold">Store: {store}</div>
          <div className="flex flex-row justify-between w-full max-w-md items-center">
            <div className="flex flex-row gap-4 items-center">
              <FontAwesomeIcon onClick={() => navigate(AVAILABLE_ROUTES.DASHBOARD)} icon={faArrowLeft} />
              <p>{SCAN_TYPE_LABELS[target]}</p>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <FontAwesomeIcon onClick={refreshState} icon={faArrowsRotate} />
              <Dropdown
                options={scannableFields.map((name) => {
                  return { label: name, value: name }
                })}
                onSelect={setField}
                theValue={{ value: field, label: field }}
              />
            </div>
          </div>
        </div>
        <div className="relative">{renderScanner()}</div>
        {target === SCAN_TYPES.PLACING && (
          <div className="flex flex-row items-center gap-5">
            <span>Current SL:</span>
            <Dropdown
              disabled={Boolean(
                scannedInfo?.scanResult?.result === 'Allocated' || scannedInfo?.scanResult?.result === 'Unallocated'
              )}
              isSearchable
              options={SLs.map(({ name }) => {
                return { label: name, value: name }
              })}
              onSelect={(name) => setCurrentSLToPlace(SLs.find((s) => name === s.name))}
              theValue={{ value: currentSLToPlace?.name, label: currentSLToPlace?.name }}
            />
          </div>
        )}
        {errorMessage && (
          <ErrorMessage title="Error!" message={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
        )}
        {scannedInfo ? (
          <WrappedComponent
            field={field}
            scannedInfo={scannedInfo}
            setErrorMessage={setErrorMessage}
            SLs={SLs}
            refreshState={refreshState}
            handleManualScan={handleManualScan}
            currentSLToPlace={currentSLToPlace}
            {...props}
          />
        ) : (
          renderCapture()
        )}
      </div>
    )
  }
}

export default withScanner
