import 'notyf/notyf.min.css' // for React, Vue and Svelte
import { createContext } from 'react'
import { Notyf } from 'notyf'
const notyf = new Notyf()

const initialState = {}
const ToastContext = createContext(initialState)

function ToastContextProvider({ children }) {
  const successToast = (message) => notyf.success(message)

  const errorToast = (message) => notyf.error(message)

  return <ToastContext.Provider value={{ successToast, errorToast }}>{children}</ToastContext.Provider>
}
export { ToastContextProvider, ToastContext }
