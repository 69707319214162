import { useContext, useMemo, useState } from 'react'
import { WMS_BOX_STATE_LABELS } from '../../../fixtures/boxStateConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { ToastContext } from '../../../contexts/toastContext'
import styled from 'styled-components'

const BoxContainer = styled.div`
  color: ${({ isAcceptable }) => (isAcceptable ? 'black' : '	#bdbdbd')};
  border-color: ${({ isSelected }) => (isSelected ? '#71c7ec' : '	#bdbdbd')};
  ${({ isSelected }) =>
    isSelected &&
    `
    box-shadow: 0 0 7px 0 #71c7ec;
  `}
`

const Boxes = ({ boxes, selectedBoxes, setSelectedBoxes }) => {
  const { matchedBox, otherBoxes } = useMemo(() => {
    return { matchedBox: boxes.filter((b) => b.isMatched)[0], otherBoxes: boxes.filter((b) => !b.isMatched) }
  }, [boxes])
  const [isBoxesExpanded, setIsBoxesExpanded] = useState(!boxes.some((b) => b.isMatched))
  const { errorToast } = useContext(ToastContext)

  const handleCheckboxClick = (box) => {
    if (box.isMatched && box.acceptable) {
      errorToast(`Box is an exact match and therefore cannot be unchecked`)
      return
    }
    if (!box.acceptable) {
      errorToast(`Box is not in good state to be accepted (${WMS_BOX_STATE_LABELS[box?.state]})`)
      return
    }
    setSelectedBoxes((list) => {
      return selectedBoxes.includes(box.id) ? list.filter((id) => id !== box.id) : [...list, box.id]
    })
  }
  return (
    <div className="w-full rounded-xl p-2 border border-gray-900">
      <div className={`flex flex-col gap-2`}>
        {matchedBox && (
          <Box
            box={matchedBox}
            handleCheckboxClick={handleCheckboxClick}
            isSelected={selectedBoxes.includes(matchedBox.id)}
          />
        )}
        <span className="flex flex-row gap-1 items-center" onClick={() => setIsBoxesExpanded((v) => !v)}>
          <FontAwesomeIcon icon={isBoxesExpanded ? faCaretDown : faCaretRight} />
          <span>{matchedBox && 'Other '}Boxes:</span>
        </span>
        {isBoxesExpanded &&
          otherBoxes.map((box) => (
            <Box box={box} handleCheckboxClick={handleCheckboxClick} isSelected={selectedBoxes.includes(box.id)} />
          ))}
      </div>
    </div>
  )
}

const Box = ({ box, handleCheckboxClick, isSelected }) => {
  return (
    <BoxContainer
      onClick={() => handleCheckboxClick(box)}
      key={box.id}
      className="grid grid-cols-3 w-full rounded-xl p-2 border-2 align-top"
      isAcceptable={box?.acceptable}
      isSelected={isSelected}
    >
      <span className="col-span-2">
        {box?.name || 'No Name'}{' '}
        {box?.isMatched && (
          <span className="inline-flex flex-row gap-1 items-center">
            <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
            <span className="text-xs text-green-500">Exact Match</span>
          </span>
        )}
      </span>
      <span className="text-end col-span-1">{WMS_BOX_STATE_LABELS[box?.state]}</span>
      <span className="col-span-2">{box?.barcode || 'No Barcode'}</span>
      <span className="text-end col-span-1">Pieces: {box?.pieces || 'N/A'}</span>
    </BoxContainer>
  )
}

export default Boxes
