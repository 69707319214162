import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const ErrorMessage = ({ title, message, clearErrorMessage }) => {
  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative flex flex-col gap-1"
      role="alert"
    >
      <div className="flex flex-row justify-between">
        <strong className="font-bold">{title}</strong>
        {clearErrorMessage && <FontAwesomeIcon className="cursor-pointer" icon={faClose} onClick={clearErrorMessage} />}
      </div>
      <span className="block sm:inline">{message}</span>
    </div>
  )
}

export default ErrorMessage
