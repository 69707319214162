import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../components/Auth/Login'
import ControlPanel from '../components/ControlPanel/ControlPanel'
import { AVAILABLE_ROUTES } from '../fixtures/routerConfig'
import PrivateRoute from './PrivateRoute'
import { ToastContextProvider } from '../contexts/toastContext'
import ReceivingView from '../components/Receiving/ReceivingView'
import PlacingView from '../components/Placing/PlacingView'
import ShippingView from '../components/Shipping/ShippingView'

const Router = () => {
  return (
    <ToastContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path={AVAILABLE_ROUTES.LOGIN} element={<Login />} />
          <Route
            path={AVAILABLE_ROUTES.DASHBOARD}
            element={
              <PrivateRoute>
                <ControlPanel />
              </PrivateRoute>
            }
          />
          <Route
            path={AVAILABLE_ROUTES.RECEIVING}
            element={
              <PrivateRoute>
                <ReceivingView />
              </PrivateRoute>
            }
          />
          <Route
            path={AVAILABLE_ROUTES.PLACING}
            element={
              <PrivateRoute>
                <PlacingView />
              </PrivateRoute>
            }
          />
          <Route
            path={AVAILABLE_ROUTES.SHIPPING_OUT}
            element={
              <PrivateRoute>
                <ShippingView />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ToastContextProvider>
  )
}

export default Router
