import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { login } from '../../apis/auth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { LOCAL_STORAGE_TOKEN_KEY } from '../../fixtures/localStorageConstants'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const handleLogin = async () => {
    setErrorMessage('')
    try {
      const body = {
        email,
        password,
      }
      const res = await login(body)
      if (res.status) {
        axios.defaults.headers.common.token = res.data.token
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, res.token)
        navigate('/')
      } else {
        setErrorMessage(res.message || 'Issue ocurred when trying to login, please try again')
      }
    } catch (err) {
      setErrorMessage('Network Error, please try again later')
      console.error('Error ocurred when trying to login: ', err)
    }
  }

  return (
    <div className="flex flex-col gap-8 items-center pt-48 bg-slate-200 h-screen text-center">
      <div>Hello There!</div>
      <div>Please login with your credentials to access app features</div>
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      <input
        className="rounded-lg py-1 px-2"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
      />
      <div className="relative">
        <input
          className="rounded-lg py-1 px-2"
          type={isPasswordShown ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <FontAwesomeIcon
          className="absolute top-2 right-2 text-stone-400 cursor-pointer"
          onClick={() => setIsPasswordShown((v) => !v)}
          icon={isPasswordShown ? faEye : faEyeSlash}
        />
      </div>

      <button className="rounded-3xl bg-primary text-white py-2 px-5" onClick={handleLogin}>
        Login
      </button>
    </div>
  )
}

export default Login
