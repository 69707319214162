import { useCallback, useEffect, useMemo } from 'react'
import ConfirmController from '../ConfirmController/ConfirmController'
import NotFoundController from '../NotFoundController/NotFoundController'
import WithScanner from '../../hocs/WithScanner'

const ReceivingView = ({ field, scannedInfo, setErrorMessage, SLs, refreshState, handleManualScan }) => {
  const { results, recognizedValues } = useMemo(() => {
    let arr = []
    if (scannedInfo?.exactMatch) arr.push({ ...scannedInfo?.exactMatch, isExactMatch: true })
    arr = [...arr, ...scannedInfo?.possibleMatches]
    return { results: arr, recognizedValues: scannedInfo?.scanResult?.scannedValues }
  }, [scannedInfo])

  useEffect(() => {
    if (!results.length && recognizedValues?.length && recognizedValues[0])
      setErrorMessage('No potential matches were found. Try manual scan')
  }, [setErrorMessage, results, recognizedValues])

  const renderControls = useCallback(() => {
    if (results.length)
      return (
        <ConfirmController
          isAllocated
          refreshState={refreshState}
          scannedInfo={scannedInfo}
          results={results}
          SLs={SLs}
          setErrorMessage={setErrorMessage}
        />
      )
    else if (recognizedValues?.length && recognizedValues[0])
      return (
        <NotFoundController
          recognizedList={recognizedValues}
          field={field}
          handleManualScan={handleManualScan}
          refreshState={refreshState}
          SLs={SLs}
          isCreateOrderVisible
        />
      )
  }, [scannedInfo, field, handleManualScan, SLs, refreshState, setErrorMessage, recognizedValues, results])
  return renderControls()
}

export default WithScanner(ReceivingView)
