import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { confirmScan } from '../../apis/scanner'
import { ToastContext } from '../../contexts/toastContext'
import Boxes from './partials/Boxes'
import GroupOrders from './partials/GroupOrders'
import UnallocatedUI from './partials/UnallocatedUI'
import BasicInfo from './partials/BasicInfo'
import PossibleMatchesSelector from './partials/PossibleMatchesSelector'

const ConfirmController = ({ scannedInfo, results, SLs, setErrorMessage, refreshState, currentSLToPlace }) => {
  const { errorToast, successToast } = useContext(ToastContext)
  const [selectedSL, setSelectedSL] = useState(null)
  const [showPositionInput, setShowPositionInput] = useState(false)
  const [positionInput, setPositionInput] = useState('')
  const [currentOrderToDisplay, setCurrentOrderToDisplay] = useState(results[0])
  const [selectedBoxes, setSelectedBoxes] = useState([])

  useEffect(() => {
    setSelectedBoxes(currentOrderToDisplay.boxes.filter((b) => b.isMatched && b.acceptable).map(({ id }) => id))
  }, [currentOrderToDisplay])

  const isAllocated = useMemo(() => {
    return Boolean(currentOrderToDisplay?.stockLocationId)
  }, [currentOrderToDisplay])

  const handleConfirmScan = useCallback(async () => {
    if (!currentOrderToDisplay.acceptable) {
      errorToast(`Order is not in acceptable state (${currentOrderToDisplay?.orderState})`)
      return
    }
    if (!isAllocated && !selectedSL) {
      setErrorMessage('Stock Location was not allocated for this order. Stock Location is Required to proceed')
      // errorToast('Stock Location was not allocated for this order. Stock Location is Required to proceed')
      return
    }
    if (!selectedBoxes.length) {
      errorToast('Please select at least 1 box to confirm')
      return
    }
    // TODO: when placing, need to use different allocateTo (the one from parent)
    try {
      await confirmScan({
        reserveId: scannedInfo.scanResult.reserveId,
        allocateTo: currentSLToPlace?.id || selectedSL?.id,
        position: positionInput,
        selectedBoxes,
      })
      refreshState()
      successToast('Scan Confirmed!')
    } catch (err) {
      console.error('Error ocurred when trying to confirm scan: ', err)
      if (err.response?.status === 400) {
        errorToast(err.response?.data.errMsg || err.response?.data.errorMsg)
      } else if (err.response?.status === 417) {
        if (err.response?.data.errorType === 'DUPLICATE_SL_POSITION') {
          setErrorMessage(
            'This SL is being used already, please provide some suffix (e.g. 1) to differentiate from other orders assigned to this SL'
          )
          // errorToast(
          //   'This SL is being used already, please provide some suffix (e.g. 1) to differentiate from other orders assigned to this SL'
          // )
          setShowPositionInput(true)
        }
      }
    }
  }, [
    successToast,
    errorToast,
    refreshState,
    setErrorMessage,
    isAllocated,
    selectedBoxes,
    scannedInfo,
    selectedSL,
    positionInput,
    currentOrderToDisplay,
    currentSLToPlace,
  ])
  return (
    <>
      {results.length > 1 && (
        <>
          <PossibleMatchesSelector
            matches={results}
            currentOrder={currentOrderToDisplay}
            setCurrentOrder={setCurrentOrderToDisplay}
          />
          <div>Exact match is {results.find((order) => order.isExactMatch).orderNo}</div>
        </>
      )}
      <BasicInfo
        isAllocated={isAllocated}
        currentOrderToDisplay={currentOrderToDisplay}
        matchedValue={scannedInfo.scanResult.matchedValue}
      />
      <UnallocatedUI
        isAcceptable={currentOrderToDisplay?.acceptable}
        isAllocated={isAllocated}
        SLs={SLs}
        selectedSL={selectedSL}
        setSelectedSL={setSelectedSL}
        showPositionInput={showPositionInput}
        positionInput={positionInput}
        setPositionInput={setPositionInput}
      />
      <Boxes
        boxes={currentOrderToDisplay?.boxes.sort((a, b) => b.isMatched - a.isMatched || b.acceptable - a.acceptable)}
        selectedBoxes={selectedBoxes}
        setSelectedBoxes={setSelectedBoxes}
      />
      <button
        disabled={!currentOrderToDisplay?.acceptable}
        onClick={handleConfirmScan}
        className="w-full rounded-3xl bg-primary disabled:bg-gray-400 text-white py-2 px-5 h-fit"
      >
        Confirm
      </button>
      <GroupOrders groupOrders={currentOrderToDisplay?.groupOrders} id={currentOrderToDisplay?.id} />
    </>
  )
}

export default ConfirmController
