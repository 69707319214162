import * as jose from 'jose'

const useAuth = (token) => {
  let userInfo
  try {
    userInfo = jose.decodeJwt(token) || null
  } catch (error) {
    console.error('Error trying to decode jwt: ', error)
  }
  let isAuthenticated = false
  if (userInfo) {
    const userInfoKeys = Object.keys(userInfo)
    isAuthenticated = userInfoKeys.includes('id') && userInfoKeys.includes('name') && userInfoKeys.includes('role')
  }

  return isAuthenticated
}

export default useAuth
