import { useEffect, useMemo } from 'react'
import WithScanner from '../../hocs/WithScanner'
import NotFoundController from '../NotFoundController/NotFoundController'
import ConfirmController from '../ConfirmController/ConfirmController'

const PlacingView = ({
  field,
  scannedInfo,
  setErrorMessage,
  SLs,
  refreshState,
  handleManualScan,
  currentSLToPlace,
}) => {
  const { results, recognizedValues } = useMemo(() => {
    let arr = []
    if (scannedInfo?.exactMatch) arr.push({ ...scannedInfo?.exactMatch, isExactMatch: true })
    arr = [...arr, ...scannedInfo?.possibleMatches]
    return { results: arr, recognizedValues: scannedInfo?.scanResult?.scannedValues }
  }, [scannedInfo])

  useEffect(() => {
    if (!results.length && recognizedValues?.length && recognizedValues[0])
      setErrorMessage('No potential matches were found. Try manual scan')
  }, [setErrorMessage, results, recognizedValues])

  if (results.length)
    return (
      <ConfirmController
        isAllocated
        refreshState={refreshState}
        scannedInfo={scannedInfo}
        results={results}
        SLs={SLs}
        setErrorMessage={setErrorMessage}
        currentSLToPlace={currentSLToPlace}
      />
    )
  else if (recognizedValues?.length && recognizedValues[0])
    return (
      <NotFoundController
        recognizedList={recognizedValues}
        field={field}
        handleManualScan={handleManualScan}
        refreshState={refreshState}
        SLs={SLs}
        isCreateOrderVisible
      />
    )
}

export default WithScanner(PlacingView)
