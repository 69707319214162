export const SCAN_FIELD_TYPES = {
  ORDER_NUMBER: 'OrderNumber',
  BAR_CODE: 'BarCode',
  CUSTOMER_NAME: 'CustomerName',
}

export const SCAN_FIELD_TYPES_LIST = [
  SCAN_FIELD_TYPES.ORDER_NUMBER,
  SCAN_FIELD_TYPES.BAR_CODE,
  SCAN_FIELD_TYPES.CUSTOMER_NAME,
]

export const SCAN_TYPES = {
  RECEIVING: 'Receiving',
  PLACING: 'Placed',
  SHIPPING_OUT: 'ShippedOut',
}

export const SCAN_TYPE_LABELS = {
  [SCAN_TYPES.RECEIVING]: 'Receiving',
  [SCAN_TYPES.PLACING]: 'Placing',
  [SCAN_TYPES.SHIPPING_OUT]: 'ShippingOut',
}
