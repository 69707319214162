import Dropdown from '../../Common/Dropdown'

const UnallocatedUI = ({
  isAcceptable,
  isAllocated,
  SLs,
  selectedSL,
  setSelectedSL,
  showPositionInput,
  positionInput,
  setPositionInput,
}) => {
  if (!isAcceptable && !isAllocated)
    return <div className="border-1 bg-stone-300 p-2 font-bold rounded-md">Not allocated to any SL</div>

  if (isAcceptable)
    return (
      <>
        {!isAllocated && (
          <div className="flex flex-row gap-2 w-full justify-center items-center">
            <span className="font-bold">
              Stock Location <span className="text-red-500">*</span>
            </span>
            <Dropdown
              options={SLs.map(({ name }) => {
                return { label: name, value: name }
              })}
              isSearchable
              theValue={{ value: selectedSL?.name, label: selectedSL?.name }}
              onSelect={(name) => setSelectedSL(SLs.find((s) => name === s.name))}
            />
          </div>
        )}
        {!isAllocated && showPositionInput && (
          <div className="flex flex-row gap-2 w-full justify-center items-center">
            <span className="font-bold">
              Suffix<span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              placeholder="1"
              className="inline-flex justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              value={positionInput}
              onChange={(e) => setPositionInput(e.target.value)}
            />
          </div>
        )}
      </>
    )
}

export default UnallocatedUI
