export const WMS_BOX_STATES = {
  EXPECTED: 'BoxStateExpected',
  RECEIVED: 'BoxStateReceived',
  PLACED: 'BoxStatePlaced',
  SHIPPED_OUT: 'BoxStateShippedOut',
}

export const WMS_BOX_STATE_LABELS = {
  [WMS_BOX_STATES.EXPECTED]: 'Expected',
  [WMS_BOX_STATES.RECEIVED]: 'Received',
  [WMS_BOX_STATES.PLACED]: 'Placed',
  [WMS_BOX_STATES.SHIPPED_OUT]: 'Shipped Out',
}
