import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const MenuItem = styled(Menu.Item)`
  margin: 0 0.2rem;
  border-left: 3px solid transparent;
  border-radius: 0.5rem;
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #fdf7ed;
    border-color: #ff6700;
    `};
`

const PossibleMatchesSelector = ({ matches, currentOrder, setCurrentOrder }) => {
  return (
    <div className="w-full flex flex-row gap-2 justify-center items-center">
      <Menu as="div" className="relative inline-block text-left">
        {({ close }) => (
          <>
            <Menu.Button className="inline-flex w-56 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <MatchCard
                    name={`${currentOrder.customerFirstName} ${currentOrder.customerLastName}`}
                    orderNo={currentOrder.orderNo}
                    isExact={currentOrder.isExactMatch}
                  />
                </div>
                <FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 mt-2 w-56 min-w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-900 py-1">
                {matches.map((match) => (
                  <MenuItem
                    isSelected={currentOrder.id === match.id}
                    className="relative p-2 flex flex-col"
                    as="div"
                    key={match.id}
                    onClick={() => {
                      setCurrentOrder(match)
                      close()
                    }}
                  >
                    <MatchCard
                      name={`${match.customerFirstName} ${match.customerLastName}`}
                      orderNo={match.orderNo}
                      isExact={match.isExactMatch}
                    />
                  </MenuItem>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {matches.length > 1 && <span className="text-red-600 font-bold">{matches.length} matches</span>}
    </div>
  )
}

const MatchCard = ({ name, orderNo, isExact }) => {
  return (
    <>
      <span className="text-start flex flex-row gap-1">
        {name}
        {isExact && (
          <span className="inline-flex flex-row gap-1 items-center">
            <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
            <span className="text-xs text-green-500">Exact Match</span>
          </span>
        )}
      </span>
      <span className="text-start">{orderNo}</span>
    </>
  )
}

export default PossibleMatchesSelector
