import axios from 'axios'

export const getListOfScannableFields = async () => {
  const res = await axios.get(`${process.env.REACT_APP_WAREHOUSE_API}/scanner/scannable-fields`)

  return res.data
}

export const scanLabelFile = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_WAREHOUSE_API}/scanner/by/file`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res.data
}

export const manualScan = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_WAREHOUSE_API}/scanner/by/value`, body)

  return res.data
}

export const confirmScan = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_WAREHOUSE_API}/scanner/confirm-ng`, body)

  return res.data
}

export const getSLs = async (page = 1, pageSize = 100000) => {
  const res = await axios.get(
    `${process.env.REACT_APP_WAREHOUSE_API}/warehouse/stock-location?page_size=${pageSize}&page=${page}`
  )

  return res.data
}

export const createOrder = async (body) => {
  const res = await axios.post(`${process.env.REACT_APP_WAREHOUSE_API}/scanner/manual-box`, body)

  return res.data
}
