import { useEffect, useState } from 'react'
import Modal from 'react-modal'

const ManualScanModal = ({
  isManualScanModalOpen,
  setIsManualScanModalOpen,
  field,
  placeholderValue,
  handleManualScan,
}) => {
  const [manualScanValue, setManualScanValue] = useState(placeholderValue || '')

  useEffect(() => {
    setManualScanValue(placeholderValue || '')
  }, [placeholderValue])

  return (
    <Modal
      isOpen={isManualScanModalOpen}
      onRequestClose={() => setIsManualScanModalOpen(false)}
      className="flex flex-col gap-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 border p-5 outline-none overflow-auto rounded-lg bg-zinc-400 text-white"
      ariaHideApp={false}
    >
      <div className="flex flex-col gap-2">
        <span className="font-bold">{field}</span>
        <input
          className="rounded-lg py-1 px-2 border w-full text-black"
          type="text"
          value={manualScanValue}
          onChange={(e) => setManualScanValue(e.target.value)}
          placeholder={`provide ${field}`}
        />
      </div>
      <div className="flex flex-row justify-evenly">
        <button className="px-2 py-1 bg-marker w-fit rounded-2xl" onClick={() => setIsManualScanModalOpen(false)}>
          Cancel
        </button>
        <button
          className="px-2 py-1 bg-primary w-fit rounded-2xl"
          onClick={() => handleManualScan({ value: manualScanValue, close: () => setIsManualScanModalOpen(false) })}
        >
          Manual Scan
        </button>
      </div>
    </Modal>
  )
}
export default ManualScanModal
