import { useCallback, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import Dropdown from '../Common/Dropdown'
import ErrorMessage from '../Common/ErrorMessage'
import { createOrder } from '../../apis/scanner'
import { ToastContext } from '../../contexts/toastContext'
import { SCAN_FIELD_TYPES } from '../../fixtures/scanTypesConfig'

const CreateBoxModal = ({ isBoxModalOpen, setIsBoxModalOpen, placeholderValue, field, SLs, refreshState }) => {
  const [orderNo, setOrderNo] = useState('')
  const [barcodeValue, setBarcodeValue] = useState('')
  const [selectedSL, setSelectedSL] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const { errorToast, successToast } = useContext(ToastContext)

  const handleCreateOrder = useCallback(
    async (orderNo, allocateTo, barcode) => {
      const body = {
        orderNo,
        allocateTo,
        barcode: barcode || undefined,
      }

      try {
        await createOrder(body)
        // INFO: if here, order created successfully
        successToast('Order created successfully!')
        refreshState()
      } catch (err) {
        console.error('Error ocurred when trying to create an order: ', err)
        if (err.response?.status === 400) {
          errorToast(err.response?.data.errMsg)
        } else if (err.response?.status === 417) {
          // TODO: check with Siavash how to handle 417 on manual-box API
        }
      }
    },
    [errorToast, successToast, refreshState]
  )

  useEffect(() => {
    if (field === SCAN_FIELD_TYPES.ORDER_NUMBER) {
      setOrderNo(placeholderValue || '')
    } else if (field === SCAN_FIELD_TYPES.BAR_CODE) {
      setBarcodeValue(placeholderValue || '')
    }
  }, [placeholderValue, field])

  const handleCreateOrderClick = () => {
    if (!orderNo) {
      setErrorMessage('Order Number is required!')
      return
    }
    if (!selectedSL) {
      setErrorMessage('Stock Location is required!')
      return
    }
    handleCreateOrder(orderNo, selectedSL.id, barcodeValue, setErrorMessage)
  }

  return (
    <Modal
      isOpen={isBoxModalOpen}
      onRequestClose={() => setIsBoxModalOpen(false)}
      ariaHideApp={false}
      className="flex flex-col gap-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 border p-5 outline-none overflow-auto rounded-lg bg-zinc-400 text-white"
    >
      <div>Create Order</div>
      {errorMessage && (
        <ErrorMessage title="Error!" message={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
      )}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            Order Number <span className="text-red-500">*</span>
          </span>
          <input
            className="rounded-lg py-1 px-2 border w-full text-black"
            type="text"
            value={orderNo}
            onChange={(e) => setOrderNo(e.target.value)}
            placeholder="Provide Order Number"
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-bold">
            Stock Location <span className="text-red-500">*</span>
          </span>
          <Dropdown
            options={SLs.map(({ name }) => {
              return { label: name, value: name }
            })}
            isSearchable
            theValue={{ value: selectedSL?.name, label: selectedSL?.name }}
            onSelect={(name) => setSelectedSL(SLs.find((s) => name === s.name))}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-bold">Barcode</span>
          <input
            className="rounded-lg py-1 px-2 border w-full text-black"
            type="text"
            value={barcodeValue}
            onChange={(e) => setBarcodeValue(e.target.value)}
            placeholder="Barcode (Optional)"
          />
        </div>
      </div>
      <div className="flex flex-row justify-evenly">
        <button className="px-2 py-1 bg-marker w-fit rounded-2xl" onClick={() => setIsBoxModalOpen(false)}>
          Cancel
        </button>
        <button className="px-2 py-1 bg-primary w-fit rounded-2xl" onClick={handleCreateOrderClick}>
          Create Order
        </button>
      </div>
    </Modal>
  )
}

export default CreateBoxModal
